
/*
 * TUTOR DIALOG
 */
\:root
  --border-radius: 10px
  --min-dialog-inline-size: 230px
  --max-dialog-inline-size: 500px

dialog
  // display: none
  // position: absolute
  margin: auto
  z-index: 10
  border: none
  &::backdrop
    position: absolute
    inset-inline-start: 0px
    inset-block-start: 0px
    inline-size: 100%
    block-size: 100%
    opacity: 0.66
    background-color: black
  &.tutor
    --inline-size: 100%
    --block-size: 100%
    max-inline-size: none
    max-block-size: none
    inline-size: min(100%, var(--inline-size))
    block-size: min(100%, var(--block-size))
    @media (max-width: 555px) and (max-height: 999px), (max-width: 999px) and (max-height: 555px)
      --border-inline-size: 0px
      --border-block-size: 0px
  &.dialog
    --inline-size: auto
    --block-size: auto
    border-radius: var(--border-radius)
    min-inline-size: var(--min-dialog-inline-size)
    max-inline-size: var(--max-dialog-inline-size)
    inline-size: 60%
    padding: var(--padding)
    section.panel
      flex: none !important
      border: none !important
  iframe
    border: none
    inline-size: 100%
    block-size: 100%
