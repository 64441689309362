/* modifiers */
$step: 10%
$half-step: calc($step / 2)

/* color variables */
$black: hsl(0deg, 0%, 0%)
$deepgray: hsl(0deg, 0%, 20%)
$darkgray: hsl(0deg, 0%, 40%)
$gray: hsl(0deg, 0%, 60%)
$lightgray: hsl(0deg, 0%, 80%)
$white: hsl(0deg, 0%, 100%)

$purple: hsl(270deg, 50%, 60%)
$firebrick: hsl(0deg, 60%, 50%)
$red: hsl(0deg, 100%, 60%)
$orange: hsl(48deg, 100%, 50%)
$olive: hsl(60deg, 50%, 60%)
$yellow: hsl(60deg, 100%, 50%)

$deepgreen: hsl(90deg, 100%, 20%)
$darkgreen: hsl(90deg, 50%, 40%)
$green: hsl(90deg, 50%, 60%)
$lightgreen: hsl(90deg, 100%, 80%)

$deepblue: hsl(210deg, 100%, 20%)
$darkblue: hsl(210deg, 50%, 40%)
$blue: hsl(210deg, 50%, 60%)
$lightblue: hsl(210deg, 100%, 80%)

/* special */
$dot: #faa400
$kiwi: #d0e8c8

/* planets */
$brightwhite: #eee
$outerspace: #202a58

/* theme colors for planets */
$planet_empty: #404c8d
$planet_fill: #5e6aa2
