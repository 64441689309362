// Mixins

@mixin reader-visible
  position: absolute
  margin: -1px
  block-size: 1px
  inline-size: 1px
  padding: 0px
  overflow: hidden
  background: transparent
  font-size: 0px
